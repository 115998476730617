import {
  AppBar,
  Box,
  ClickAwayListener,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import React, { FC, PropsWithChildren, useContext } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HeaderContext, NavChildOption, NavOption } from './context';
import { Container } from '../Container';
import LogoIcon from '@/assets/icons/Logo.svg';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { ROUTES } from '@/libs/routing';
import { AuthButtons } from './AuthButtons';
import { AuthButtonsMobile } from './AuthButtonsMobile';
import { useAnalytics } from '@/libs/analytics';
import { fbq } from '@/libs/analytics/Facebook';

const HeaderSubNav: FC<PropsWithChildren<{ items: NavChildOption[] }>> = ({ items }) => {
  const { setOpenedSubMenu, setMenuIsOpen } = useContext(HeaderContext);
  const handleClick = (e: React.MouseEvent, item: NavChildOption): void => {
    if (!item.link) {
      e.preventDefault();
    }
    item.onClick && item.onClick();
    setOpenedSubMenu(null);
    setMenuIsOpen(false);
  };
  return (
    <Box className={styles.subNav}>
      <Container className={styles.subNavContainer}>
        <List className={styles.subNavList}>
          {items.map((item) => {
            const IconComponent = item.icon;
            return (
              <ListItem key={item.id} className={styles.subNavListItem}>
                <Link
                  href={item.link || '#'}
                  target={item.target || '_self'}
                  onClick={(e): void => {
                    handleClick(e, item);
                  }}
                  className={styles.subNavItem}
                >
                  <Box className={styles.subNavItemIcon}>
                    <IconComponent />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" className={styles.subNavItemTitle}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" className={styles.subNavItemDesc}>
                      {item.desc}
                    </Typography>
                  </Box>
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Container>
    </Box>
  );
};

const HeaderNavItem: FC<{ option: NavOption }> = ({ option }) => {
  const { setOpenedSubMenu, openedSubMenu, setMenuIsOpen } = useContext(HeaderContext);
  const handleClick = (e: React.MouseEvent, option: NavOption): void => {
    if (!option.link) {
      e.preventDefault();
      setOpenedSubMenu(openedSubMenu && openedSubMenu === option.id ? null : option.id);
    } else {
      setOpenedSubMenu(null);
      setMenuIsOpen(false);
    }
  };
  const handleClickAway = (): void => {
    if (option.id === openedSubMenu) {
      setOpenedSubMenu(null);
    }
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ListItem
        className={clsx(styles.navListItem, {
          [styles.opened]: openedSubMenu === option.id,
        })}
      >
        <Link
          href={option.link || '#'}
          onClick={(e): void => {
            handleClick(e, option);
          }}
          className={styles.navListItemLink}
        >
          {option.title}
          {!option.link && (
            <Box component="span" className={styles.navListItemLinkIcon}>
              <KeyboardArrowDownIcon />
            </Box>
          )}
        </Link>
        {!option.link && option?.children && <HeaderSubNav items={option.children} />}
      </ListItem>
    </ClickAwayListener>
  );
};

const HeaderNav: FC = () => {
  const { config: navConfig } = useContext(HeaderContext);

  return (
    <Box component="nav" className={styles.nav}>
      <List className={styles.navList}>
        {navConfig.map((option) => {
          return <HeaderNavItem key={option.id} option={option} />;
        })}
      </List>
    </Box>
  );
};

export const Header: FC = () => {
  const { t } = useTranslation('common');
  const { menuIsOpen, setMenuIsOpen } = useContext(HeaderContext);
  const { requestDemoClicked } = useAnalytics();
  return (
    <AppBar className={styles.appBar} component="header" position="sticky" id="header">
      <Container className={styles.container}>
        <Link href="/" className={styles.logo}>
          <LogoIcon />
        </Link>
        <Box
          className={clsx(styles.menu, {
            [styles.menuIsOpen]: menuIsOpen,
          })}
        >
          <HeaderNav />
          <Box className={styles.appBarButtons}>
            <Link
              href={ROUTES.DEMO}
              className={styles.appBarReqButton}
              onClick={(): void => {
                fbq('track', 'Contact');
                setMenuIsOpen(false);
                requestDemoClicked();
              }}
            >
              {t('header.demoRequest')}
            </Link>
            <AuthButtons />
          </Box>
        </Box>
        <AuthButtonsMobile />
        <Box
          className={clsx('isMobile', styles.menuToggle, {
            [styles.menuIsOpen]: menuIsOpen,
          })}
          onClick={(): void => {
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          <Box component="span"></Box>
          <Box component="span"></Box>
          <Box component="span"></Box>
        </Box>
      </Container>
    </AppBar>
  );
};
