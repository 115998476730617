import { useTranslation } from 'next-i18next';
import { EXTERNAL_ROUTES, ROUTES } from '@/libs/routing';
import { useAnalytics } from '@/libs/analytics';

export type FooterNavOption = {
  label: string;
  children: {
    label: string;
    link?: string;
    onClick?: () => void;
    target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  }[];
};

export const useFooterNav = (): {
  config: FooterNavOption[];
} => {
  const { t } = useTranslation('common');
  const { chromeExtensionClicked } = useAnalytics();
  return {
    config: [
      {
        label: t('footer.nav.platform'),
        children: [
          {
            label: t('footer.nav.whyPowerlead'),
            link: ROUTES.WHY_POWERLEAD,
          },
          {
            label: t('footer.nav.chromeExtension'),
            onClick: chromeExtensionClicked,
            link: EXTERNAL_ROUTES.EXTENSION_MARKET,
            target: '_blank',
          },
          {
            label: t('footer.nav.pricing'),
            link: ROUTES.PRICING,
          },
        ],
      },
      {
        label: t('footer.nav.company'),
        children: [
          {
            label: t('footer.nav.about'),
            link: ROUTES.ABOUT,
          },
          {
            label: t('footer.nav.ourData'),
            link: ROUTES.OUR_DATA,
          },
          {
            label: t('footer.nav.privacyCenter'),
            link: ROUTES.PRIVACY_CENTER,
          },
          {
            label: t('footer.nav.contactUs'),
            link: ROUTES.CONTACT_US,
          },
        ],
      },
      {
        label: t('footer.nav.resources'),
        children: [
          {
            label: t('footer.nav.successStories'),
            link: ROUTES.SUCCESS_STORIES,
          },
          {
            label: t('footer.nav.helpCenter'),
            link: EXTERNAL_ROUTES.HELP_CENTER,
            target: '_blank',
          },
          {
            label: t('footer.nav.becomePartner'),
            link: ROUTES.CONTACT_US,
          },
        ],
      },
    ],
  };
};
