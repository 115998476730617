import { useAuth } from '@/features/auth';
import { toDashboardLogin, toDashboardSignUp } from '@/libs/routing';
import { Button } from '@mui/material';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useAnalytics } from '@/libs/analytics';

export const AuthButtons: FC = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { startForFreeClicked } = useAnalytics();
  if (auth.isAuthenticated) {
    return (
      <Button
        color="secondary"
        onClick={() => {
          toDashboardLogin();
        }}
        variant="contained"
        className={styles.appBarButton}
      >
        {t('header.enterAccount')}
      </Button>
    );
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={toDashboardLogin}
        color="secondary"
        className={styles.appBarButton}
      >
        {t('header.login')}
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          toDashboardSignUp();
          startForFreeClicked();
        }}
        variant="contained"
        className={styles.appBarButton}
      >
        {t('header.startFree')}
      </Button>
    </>
  );
};
