import { FC, HTMLAttributes, ReactElement } from 'react';
import styles from './styles.module.scss';
import { Box, Button, Typography } from '@mui/material';
import LeftBgIcon from './assets/leftIonBg.svg';
import RightBgIcon from './assets/rightIconBg.svg';
import clsx from 'clsx';
import { EXTERNAL_ROUTES } from '@/libs/routing';
import { useTranslation } from 'next-i18next';
import { useAuth } from '@/features/auth';
import { useAnalytics } from '@/libs/analytics';

type StartFreeProp = Pick<HTMLAttributes<HTMLDivElement>, 'className'> & {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  baseText?: string;
  onClick?: () => void;
  titleClassName?: string;
};

export const StartFreeSection: FC<StartFreeProp> = ({
  title,
  subtitle,
  baseText,
  titleClassName,
  ...divProps
}) => {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const buttonText = auth.isAuthenticated
    ? t('startFreeForm.buttonAuthenticated')
    : t('startFreeForm.buttonUnauthenticated');

  const buttonHref = auth.isAuthenticated
    ? EXTERNAL_ROUTES.DASHBOARD_SIGN_IN
    : EXTERNAL_ROUTES.DASHBOARD_SIGN_UP;

  const { startForFreeClicked } = useAnalytics();
  return (
    <Box className={clsx(styles.container, divProps.className)} component="section">
      <Box className={styles.leftBgIcon}>
        <LeftBgIcon />
      </Box>
      <Box className={styles.rightBgIcon}>
        <RightBgIcon />
      </Box>
      <Box className={styles.body}>
        <Typography variant="h2" className={clsx(styles.title, titleClassName)}>
          {title}
        </Typography>
        <Typography variant="body1" className={styles.subtitle}>
          {subtitle}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          href={buttonHref}
          className={styles.button}
        >
          <span onClick={startForFreeClicked}>{buttonText}</span>
        </Button>
        {baseText && (
          <Typography variant="body1" className={styles.baseText}>
            {baseText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
