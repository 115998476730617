import { FC, ReactElement } from 'react';

import { ArrowForward } from '@mui/icons-material';
import Link, { LinkProps } from 'next/link';

import styles from './styles.module.scss';
import clsx from 'clsx';

export const ArrowLink: FC<
  LinkProps & {
    text: string | ReactElement;
    className?: string;
  }
> = ({ text, ...props }) => {
  return (
    <Link {...props} className={clsx(styles.link, props?.className)}>
      {text}
      <ArrowForward />
    </Link>
  );
};
