import { Container } from '@/components';
import { FeedbackCarousel } from '@/components/FeedbackCarousel';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';

const customerFeedbackItems = [
  {
    id: 'slide-0',
    text: 'Powerlead not only enhanced our lead generation capabilities but also provided substantial cost savings. We halved our data service expenses, and the accuracy and affordability of Powerlead made it an indispensable part of our streamlined tech stack.',
    userAvatar: '/images/customerAvatars/RangeTellerAuthor.png',
    userName: 'Daniel Goldstone',
    userPosition: 'CEO RangeTeller',
  },
  {
    id: 'slide-1',
    text: 'Powerlead has transformed our approach to global outreach. Its precision and breadth of data have enabled us to target our efforts more effectively, resulting in more meaningful engagements and a significant increase in booked meetings.',
    userAvatar: '/images/customerAvatars/moblersAuthor.jpeg',
    userName: 'Guy Doron',
    userPosition: 'CEO Moblers',
  },
  {
    id: 'slide-2',
    text: 'Powerlead has been a game-changer for our recruitment strategy. The competitive edge provided by accessing direct dials has transformed our approach, allowing us to discreetly and effectively initiate conversations, increasing our success in placing top talent in strategic positions.',
    userAvatar: '/images/customerAvatars/talentedgeAuthor.webp',
    userName: 'Yael Barak Cohen',
    userPosition: 'CEO & Founder Talentedge',
  },
  {
    id: 'slide-3',
    text: 'Implementing Powerlead has been a game-changer for our client acquisition. The ability to access reliable and accurate data has enabled us to reach out to potential clients with speed and confidence, ensuring every interaction drives our business goals forward.',
    userAvatar: '/images/customerAvatars/LGFGAuthor.png',
    userName: 'Dimitry Toukhcher',
    userPosition: 'CEO LGFG',
  },
];

export const CustomerFeedbackSection: FC = () => {
  const { t } = useTranslation('common');

  return (
    <Box component="section" className={styles.section}>
      <Container>
        <Box className="isDesktop">
          <Typography variant="h2" className={styles.title}>
            {t('customerFeedback.title')}
          </Typography>
          <Typography variant="subtitle1" className={styles.subtitle}>
            {t('customerFeedback.subtitle')}
          </Typography>
        </Box>
        <Box className="isMobile">
          <Typography variant="h2" className={styles.title}>
            {t('customerFeedback.title')}
          </Typography>
          <Typography variant="subtitle1" className={styles.subtitle}>
            {t('customerFeedback.subtitle')}
          </Typography>
        </Box>
        <FeedbackCarousel items={customerFeedbackItems} />
      </Container>
    </Box>
  );
};
