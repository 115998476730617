import Script from 'next/script';
import { FC } from 'react';

export const OneTrust: FC = () => (
  <>
    <Script
      id="ot-sdk"
      strategy="lazyOnload"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
      data-domain-script="e3ecab90-caae-488c-9adf-14a17ddfb195-test"
    ></Script>
    <Script
      id="ot-start"
      strategy="lazyOnload"
      type="text/javascript"
    >{`function OptanonWrapper() {}`}</Script>
  </>
);
