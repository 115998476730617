import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import CollectIcon from '@/assets/icons/Collect.svg';
import DbIcon from '@/assets/icons/Db.svg';
import ChromeIcon from '@/assets/icons/Chrome.svg';
import SalesIcon from '@/assets/icons/Sales.svg';
import MarketingIcon from '@/assets/icons/Marketing.svg';
import RecruitingIcon from '@/assets/icons/Recruiting.svg';
import HelpCenterIcon from '@/assets/icons/HelpCenter.svg';
// import BlogIcon from '@/assets/icons/Blog.svg';
import InfoIcon from '@/assets/icons/Info.svg';
import PrivacyIcon from '@/assets/icons/Privacy.svg';
import ContactUsIcon from '@/assets/icons/ContactUs.svg';
import { EXTERNAL_ROUTES, ROUTES } from '@/libs/routing';
import { useAnalytics } from '@/libs/analytics';

export type NavChildOption = {
  icon: FC;
  title: string;
  desc: string;
  id: string;
  parentId: string;
  onClick?: () => unknown;
  link?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
};

export type NavOption = {
  title: string;
  id: string;
  link?: string;
  children?: NavChildOption[];
};

export const HeaderContext = createContext<{
  config: NavOption[];
  openedSubMenu: null | string;
  activeNavId: string | null;
  menuIsOpen: boolean;
  setMenuIsOpen: (val: boolean) => void;
  setOpenedSubMenu: (val: null | string) => void;
}>({
  openedSubMenu: null,
  activeNavId: null,
  menuIsOpen: false,
  setMenuIsOpen(): void {},
  setOpenedSubMenu(): void {},
  config: [],
});

export const HeaderProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('common');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { chromeExtensionClicked } = useAnalytics();

  const config: NavOption[] = [
    {
      title: t('header.nav.why.title'),
      id: 'nav-0',
      children: [
        {
          icon: CollectIcon,
          title: t('header.nav.platform.title'),
          desc: t('header.nav.platform.desc'),
          id: 'nav-1',
          parentId: 'nav-0',
          link: ROUTES.WHY_POWERLEAD,
        },
        {
          icon: DbIcon,
          title: t('header.nav.unlimitedData.title'),
          desc: t('header.nav.unlimitedData.desc'),
          id: 'nav-2',
          parentId: 'nav-0',
          link: ROUTES.OUR_DATA,
        },
        {
          icon: ChromeIcon,
          title: t('header.nav.extension.title'),
          desc: t('header.nav.extension.desc'),
          id: 'nav-3',
          onClick: chromeExtensionClicked,
          parentId: 'nav-0',
          target: '_blank',
          link: EXTERNAL_ROUTES.EXTENSION_MARKET,
        },
      ],
    },
    {
      title: t('header.nav.solutions.title'),
      id: 'nav-4',
      children: [
        {
          icon: SalesIcon,
          link: ROUTES.SALES_SOLUTIONS,
          title: t('header.nav.sales.title'),
          desc: t('header.nav.sales.desc'),
          id: 'nav-5',
          parentId: 'nav-4',
        },
        {
          icon: MarketingIcon,
          link: ROUTES.MARKETING_SOLUTIONS,
          title: t('header.nav.marketing.title'),
          desc: t('header.nav.marketing.desc'),
          id: 'nav-6',
          parentId: 'nav-4',
        },
        {
          icon: RecruitingIcon,
          link: ROUTES.RECRUITING_SOLUTIONS,
          title: t('header.nav.recruiting.title'),
          desc: t('header.nav.recruiting.desc'),
          id: 'nav-7',
          parentId: 'nav-4',
        },
      ],
    },
    {
      link: ROUTES.PRICING,
      title: t('header.nav.pricing.title'),
      id: 'nav-8',
    },
    {
      link: ROUTES.SUCCESS_STORIES,
      title: t('header.nav.successStories.title'),
      id: 'nav-9',
    },
    {
      title: t('header.nav.resources.title'),
      id: 'nav-10',
      children: [
        {
          icon: HelpCenterIcon,
          title: t('header.nav.helpCenter.title'),
          desc: t('header.nav.helpCenter.desc'),
          id: 'nav-10',
          parentId: 'nav-9',
          link: EXTERNAL_ROUTES.HELP_CENTER,
          target: '_blank',
        },
        // {
        //   icon: BlogIcon,
        //   title: t('header.nav.blog.title'),
        //   desc: t('header.nav.blog.desc'),
        //   id: 'nav-11',
        //   parentId: 'nav-9',
        // },
        {
          icon: InfoIcon,
          link: ROUTES.ABOUT,
          title: t('header.nav.about.title'),
          desc: t('header.nav.about.desc'),
          id: 'nav-12',
          parentId: 'nav-9',
        },
        {
          icon: PrivacyIcon,
          link: ROUTES.PRIVACY_CENTER,
          title: t('header.nav.privacyCenter.title'),
          desc: t('header.nav.privacyCenter.desc'),
          id: 'nav-13',
          parentId: 'nav-9',
        },
        {
          icon: ContactUsIcon,
          link: ROUTES.CONTACT_US,
          title: t('header.nav.contactUs.title'),
          desc: t('header.nav.contactUs.desc'),
          id: 'nav-14',
          parentId: 'nav-9',
        },
      ],
    },
  ];
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (): void => {
      setMenuIsOpen(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return (): void => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events]);

  const activeLinksMap = config.reduce((acc: Record<string, string>, item) => {
    if (item.link) {
      acc[item.link] = item.id;
      return acc;
    }

    if (item?.children) {
      item.children.forEach((inner) => {
        if (inner?.link) {
          acc[inner.link] = inner.parentId;
        }
      });
    }
    return acc;
  }, {});
  const [openedSubMenu, setOpenedSubMenu] = useState<null | string>(null);
  const activeNavId = activeLinksMap[router.route] || null;

  return (
    <HeaderContext.Provider
      value={{
        openedSubMenu,
        setOpenedSubMenu,
        activeNavId,
        config,
        menuIsOpen,
        setMenuIsOpen,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
