// @ts-nocheck
import { Components } from '@mui/material';

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: (): Record<string, unknown> => ({
      textTransform: 'initial',
      fontSize: '0.875rem',
      minWidth: 'initial',
      borderRadius: '0.375rem',
      padding: '0.5rem 1.25rem',
      lineHeight: '1.3',
      boxShadow: 'none',
      '@media screen and (max-width: 767px)': {
        fontSize: '1.125rem',
      },
    }),
  },
};
